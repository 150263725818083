import { APP_INITIALIZER, inject } from '@angular/core';
import { EdiInboundOrderDto, EdiInboundOrderEntityName } from '@nest/domain/entities-metadata/dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';

const paths = autoPathsFor<EdiInboundOrderDto>();

export const EdiInboundOrderDefaultElements = fieldsToElements(
	paths(['createdAt', 'salesChannel.name', 'foreignOrderNumber', 'order.documentNumber', 'state', 'accepted', 'responded', 'invoiceSent', 'trackingNumbersSent'])
);

export function provideEdiInboundOrdersEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);

				return () => {
					entityMetadata.registerEntity({
						entityName: EdiInboundOrderEntityName,
						defaultElements: EdiInboundOrderDefaultElements
					});
				};
			}
		}
	];
}
